import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import ProjectsScreen from "../screens/projects"

const ProjectsPage = ({ data }) => (
  <>
    <SEO
      title="Projects"
    />
    <ProjectsScreen
      projects={data}
    />
  </>
)

export default ProjectsPage

export const query = graphql`
  query AllProjects {
  allProject {
    edges {
      node {
        id
        excerpt
        name
        cover {
          url
          width
          height
          }
        }
      }
    }
  }
`

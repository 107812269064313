import React from "react"
import { ProjectCard } from "../../components/card"
import { PageTitle } from "../../components/copy"
import Layout from "../../components/layout"
import "../../styles/_global.scss"
import "./index.scss"

const ProjectsScreen = ({ projects }) => {
  const renderProjects = () => projects.allProject.edges.map(project => (
    <ProjectCard
      key={project.node.id}
      className="projectCard-item"
      project={project.node}
    />
  ))

  return (
    <Layout>
      <div className="projectsScreen u-lg-maxWidth">
        <PageTitle
          className="projectsScreen-pageTitle"
          title="Projects"
        />
        <div className="projectsScreen-projectsFeed u-marginTop48">
          {renderProjects()}
        </div>
      </div>
    </Layout>
  )
}

export default ProjectsScreen
